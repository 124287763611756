import React from "react";
import AboutSection from "./components/AboutSection";
import BannerHome from "./components/Banner";
import FeatureProject from "./components/FeatureProject";
import ServiceSection from "./components/ServiceSection";
import TeamSection from "./components/TeamSection";
import OurProcess from "./components/OurProcess";
import ReviewSection from "./components/ReviewSection";
import PartnerSection from "./components/PartnerSection";
import TalkSection from "./components/TalkSection";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

export const Home = () => {
  // Scroll to the top
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });
  return (
    <>
      <Helmet>
        <title>
          Best Offplan Projects - Apartments, Villas, Townhouses, Penthouses
        </title>
        <meta
          name="description"
          content="Looking to buy, sell luxury real estate in Dubai? Explore the best properties, apartments, and villas with DNK Real Estate – your trusted real estate partner. We are here to serve your concerns and with us, you don't have to look anywhere else."
        />
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "website",
          "name": "Best Offplan Projects - Apartments, Villas, Townhouses, Penthouses",
          "url": "https://www.dnkre.com",
          "logo": "https://www.dnkre.com/logo192.png",
          "image": "https://www.dnkre.com/logo192.png",
          "sameAs": [
            "https://www.facebook.com/dnkrealestate1/",
            "https://www.instagram.com/dnk_re/",
            "https://www.linkedin.com/company/dnkrealestate/mycompany/"
          ],
          "address": {
            "@type": "PostalAddress",
            "addressCountry": "AE",
              "streetAddress": "Suite No. 2602, Silver Tower, Marasi Drive",
              "addressLocality": "Business Bay",
              "addressRegion": "Dubai",
              "postalCode": "26048"
          },
          "description": "Leading real estate company in Dubai, specializing in luxury properties, affordable apartments, off-plan developments, waterfront villas, high-rise apartments, and investment opportunities. Offering premium and budget-friendly real estate for sale in top locations like Dubai Marina, Downtown Dubai, Palm Jumeirah, Business Bay, Jumeirah Village Circle (JVC), Dubai Hills Estate, Arabian Ranches, and Meydan. Providing the best deals on properties with low prices and exclusive offers. Expert guidance for property buyers, investors, and those looking to purchase in Dubai’s fast-growing real estate market. Recognized as the best real estate company in Dubai, offering exceptional services for buyers and investors.",
          "telephone": "+971 123 456 789"
        }
    `}
        </script>
      </Helmet>

      <div>
        <BannerHome />
        <div>
          <FeatureProject />
          <AboutSection />
          <ServiceSection />
          <TeamSection />
          <OurProcess />
          <ReviewSection />
          <PartnerSection />
          <TalkSection />
        </div>
      </div>
    </>
  );
};

export default Home;
